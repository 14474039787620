import React from 'react'
import styled from 'styled-components'
import BBShiv2Image from '../../images/billboard-shivs-2.png'
import TrackVisibility from 'react-on-screen'
import WhatIDoItems from './whatIDoItems'

const TopBorder = styled.div`
  border-top: 150px solid transparent;
  border-right: 100vw solid #3b275a;
  margin-top: -150px;
  z-index: 9;
  position: relative;
  @media (max-width: 768px) {
    border-top: 65px solid transparent;
    margin-top: -65px;
  }
`

const StyledWhatIDo = styled.div`
  background-image: linear-gradient(-180deg, #3b275a 0%, #362759 100%);
  padding-top: 120px;
  position: relative;
  h2 {
    text-align: center;
    margin: 0 0 60px 0;
    font-size: 35px;
    font-weight: 400;
    letter-spacing: 2px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.14);
    &:after {
      content: '';
      border-bottom: 2px solid #a5ed7e;
      display: block;
      width: 200px;
      margin: 0 auto;
      padding: 3px;
    }
  }
`
const ContentInner = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  padding: 0 20px 120px 20px;
`
const Shiv = styled.div`
  position: absolute;
  top: -60px;
  z-index: 99;
  right: 0;
  @media (max-width: 768px) {
    top: -40px;
  }
`

const WhatIDo = () => (
  <>
    <TopBorder />
    <StyledWhatIDo>
      <Shiv>
        <img src={BBShiv2Image} alt="Decorative Element" />
      </Shiv>
      <ContentInner>
        <h2>What I Do</h2>
        <TrackVisibility partialVisibility>
          <WhatIDoItems />
        </TrackVisibility>
      </ContentInner>
    </StyledWhatIDo>
  </>
)

export default WhatIDo
