import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'react-scroll'
import styled from 'styled-components'
import BBShiv1Image from '../../images/billboard-shivs-1.png'
import Arrow from '../../images/icon-arrow-down.svg'
import BackgroundImage from 'gatsby-background-image'

const BillboardWrapper = styled(BackgroundImage)`
  width: 100vw;
  height: 800px;
  position: relative;
  margin-top: -94px;
  .content-wrapper {
    max-width: 563px;
    padding-top: 225px;
    transition: all 300ms ease-in-out;
    h1 {
      font-size: 60px;
      text-transform: uppercase;
      .hl {
        color: ${props => props.theme.lime};
      }
    }
    p {
      margin-top: 50px;
      font-size: 23px;
      line-height: 37px;
    }
  }

  /* Large Screens */
  @media (min-width: 1550px) {
    .content-wrapper {
      padding-top: 270px;
    }
  }
  @media (min-width: 1730px) {
    .content-wrapper {
      padding-top: 315px;
    }
  }
  @media (min-width: 2000px) {
    background-position: center center;
    .content-wrapper {
      padding-top: 310px;
      p {
        margin-top: 20px;
      }
    }
  }

  /* Smaller than 1440 */
  @media (max-width: 1210px) {
    .content-wrapper {
      p {
        margin-top: 40px;
        font-size: 21px;
        line-height: 32px;
        max-width: 500px;
      }
    }
  }
  @media (max-width: 950px) {
    .content-wrapper {
      h1 {
        font-size: 50px;
      }
      p {
        font-size: 19px;
        line-height: 30px;
        max-width: 370px;
      }
    }
  }
  @media (max-width: 768px) {
    height: 80vh;
    position: relative;
    .content-wrapper {
      background: linear-gradient(
        180deg,
        rgba(10, 10, 20, 0.4) 0%,
        rgba(10, 10, 20, 0.7) 100%
      );
      position: absolute;
      left: 0;
      bottom: 0;
      margin: 0;
      width: 100%;
      max-width: 100%;
      padding: 0 20px 60px 20px;
      h1 {
        font-size: 35px;
        position: relative;
        top: -31px;
        margin: 0;
      }
      p {
        max-width: 100%;
        margin: -15px 0 0 0;
        padding: 0;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.3px;
      }
    }
  }
  @media (max-width: 400px) {
    .content-wrapper {
      p {
        letter-spacing: 0px;
      }
    }
  }
  @media (max-width: 400px) {
    .content-wrapper {
      h1 {
        font-size: 28px;
        top: -25px;
      }
      p {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
`
const BillboardInner = styled.div`
  background-image: url(${props => props.bg});
  background-repeat: no-repeat;
  background-position: bottom left;
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  padding: 0 20px 120px 20px;
  a {
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: calc(50vw - 17px);
    z-index: 998;
    .arrow {
      transition: all 0.2s ease-in-out;
      &:hover {
        cursor: pointer;
        transform: scale(1.2);
      }
    }
  }
  @media (max-width: 768px) {
    height: 75%;
    a {
      bottom: -30px;
    }
  }
`

const greeting = () => {
  const d = new Date()
  const h = d.getHours()
  let greet = ''
  if (h > 0 && h < 12) {
    greet = 'Good Morning'
  } else if (h >= 12 && h < 18) {
    greet = 'Good Afternoon'
  } else if (h >= 18 && h <= 23) {
    greet = 'Good Evening'
  }
  return greet
}

const Billboard = () => {
  const data = useStaticQuery(graphql`
    {
      desktop: file(relativePath: { eq: "home-billboard.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile: file(relativePath: { eq: "home-billboard-mobile.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const [isMobile, setMobile] = useState(false)

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    updateDimensions()
  }, [])
  const updateDimensions = () => {
    if (window.innerWidth < 768) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }

  let bgImage = data.desktop
  if (isMobile) {
    bgImage = data.mobile
  }

  return (
    <BillboardWrapper fluid={bgImage.childImageSharp.fluid}>
      <BillboardInner bg={BBShiv1Image}>
        <div className="content-wrapper">
          <h1>
            {greeting()}
            <span className="hl">!</span>
          </h1>
          <p>
            I'm an all-hands-on-deck product interface designer. I thrive when I
            have the opportunity to create something that is simple, beautiful
            &amp; easy to use. Before I show you my work, let me tell you a bit
            more about what I enjoy doing.
          </p>
        </div>
        <Link to="homeContent" smooth={true} offset={70} duration={500}>
          <img src={Arrow} alt="Scroll to content" className="arrow" />
        </Link>
      </BillboardInner>
    </BillboardWrapper>
  )
}

export default Billboard
