import React from 'react'
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'
import TrackVisibility from 'react-on-screen'
import WorkListItem from '../work/workListItem'

const ProductWorkListWrapper = styled.div`
  background-image: linear-gradient(-180deg, #191126 0%, #14081e 100%);
`
const ProductWorkListInner = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  position: relative;
  top: -80px;
  @media (max-width: 1280px) {
    margin: 0 20px;
  }
`

const ProductWorkList = () => (
  <StaticQuery
    query={WORK_PRODUCT_ITEMS_CONTENT}
    render={({ allWordpressWpWork }) => (
      <ProductWorkListWrapper>
        <ProductWorkListInner>
          {allWordpressWpWork.edges.map(({ node }) => (
            <TrackVisibility partialVisibility key={node.id}>
              <WorkListItem data={node} />
            </TrackVisibility>
          ))}
        </ProductWorkListInner>
      </ProductWorkListWrapper>
    )}
  />
)

const WORK_PRODUCT_ITEMS_CONTENT = graphql`
  query WorkProductItemsListDetails {
    allWordpressWpWork(filter: { acf: { type: { eq: "product" } } }) {
      edges {
        node {
          title
          id
          slug
          excerpt
          acf {
            expertise {
              name
              term_id
            }
            platforms {
              name
              term_id
            }
            deliverables {
              name
              term_id
            }
            image_list_bg {
              source_url
              localFile {
                childImageSharp {
                  fluid(quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            color
          }
        }
      }
    }
  }
`
export default ProductWorkList
