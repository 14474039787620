import React from 'react'
import styled from 'styled-components'
import posed from 'react-pose'
import IconUX from '../../images/icon-large-ux.svg'
import IconUI from '../../images/icon-large-ui.svg'
import IconFE from '../../images/icon-large-front-end.svg'
import IconCL from '../../images/icon-large-leadership.svg'
import WhatIDoItem from './whatIDoItem'

const Sections = styled.div`
  ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 80px;
    list-style-type: none;
    margin: 0;
    @media (max-width: 1200px) {
      grid-gap: 20px;
    }
    @media (max-width: 900px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }
`

const SectionsAnimation = posed.ul({
  open: {
    delayChildren: 20,
    staggerChildren: 180,
  },
})

const WhatIDoItems = ({ isVisible }) => (
  <Sections>
    <SectionsAnimation pose={isVisible ? 'open' : 'closed'}>
      <WhatIDoItem
        icon={IconUX}
        title="<strong>UX</strong> Design"
        description="User Research, Brainstorm, Product Design, Interaction Design, IA."
      />
      <WhatIDoItem
        icon={IconUI}
        title="<strong>UI</strong> Design"
        description="Interface Design, Visual Design, Animation, Branding, Design Systems."
      />
      <WhatIDoItem
        icon={IconFE}
        title="Front-end <strong>Development</strong>"
        description="HTML, CSS, JavaScript, ReactJS, NextJS, Gatsby, WordPress, GraphQL, Firebase."
      />
      <WhatIDoItem
        icon={IconCL}
        title="Creative <strong>Leadership</strong>"
        description="DesignOps, Team Mentorship, Presenting &amp; Speaking, Design Culture evangelism."
      />
    </SectionsAnimation>
  </Sections>
)
export default WhatIDoItems
