import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const StyledSectionHeading = styled(BackgroundImage)`
  color: #fff;
  min-height: 400px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 150px 1fr 80px;
  align-items: end;
  padding: 0 20px;
  h2 {
    text-transform: uppercase;
    text-align: center;
    padding: 20px;
    margin: 0;
    font-size: 45px;
    font-weight: 400;
    letter-spacing: 2px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.14);
  }
  p {
    align-self: flex-start;
    text-align: center;
    max-width: 750px;
    margin: 0 auto;
    padding: 0 0 20px 0;
    font-family: ${props => props.theme.poppins};
    font-size: 20px;
    line-height: 28px;
    a {
      color: #fff !important;
      text-decoration: none;
      border-bottom: 1px solid rgba(165, 237, 126, 0.38);
      transition: all 200ms ease-in-out;
      &:hover {
        border-bottom: 1px solid rgba(165, 237, 126, 1);
      }
    }
  }
`

const SectionHeading = ({ bg, heading, intro }) => (
  <StyledSectionHeading fluid={bg.childImageSharp.fluid}>
    <h2>{heading}</h2>
    <p dangerouslySetInnerHTML={{ __html: intro }} />
  </StyledSectionHeading>
)

export default SectionHeading
