import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Element } from 'react-scroll'
import Header from '../components/header/header'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Billboard from '../components/home/homeBillibard'
import WhatIDo from '../components/home/whatIDo'
import SectionHeading from '../components/home/sectionHeading'
import ClientWorkList from '../components/home/sectionClientWork'
import ProductWorkList from '../components/home/sectionProductWork'
import ArticlePageContainer from '../components/articles/articlesPageContainer'
import ContactSection from '../components/home/sectionContact'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      clientWorkSHBG: file(
        relativePath: { eq: "section-header-client-work.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      productWorkSHBG: file(
        relativePath: { eq: "section-header-product-work.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      articlesSHBG: file(relativePath: { eq: "section-header-articles.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      contactSHBG: file(relativePath: { eq: "section-header-contact.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout lightMode={false}>
      <SEO title="Home" keywords={[`design`, `developer`, `react`]} />
      <Header colour="#A5ED7E" />
      <Billboard />
      <Element name="homeContent" />
      <WhatIDo />
      <SectionHeading
        bg={data.clientWorkSHBG}
        heading="Client Work"
        intro="Creating well thought out experiences with pixel perfect user interface execution for clients is how I spend most of my time. Below are some of the projects I am most proud of."
      />
      <ClientWorkList />
      <SectionHeading
        bg={data.productWorkSHBG}
        heading="Products I've Made"
        intro="I've also been in the driver seat to create some products from scratch. I was responsible for the concept, UX &amp; UI Design, Development, establishing product-market-fit as well as ensuring there is continuous growth and expansion of features on the roadmap."
      />
      <ProductWorkList />
      <SectionHeading
        bg={data.articlesSHBG}
        heading="Articles"
        intro={`Writing original articles based on my perspective and experiences over the years has provided me with the opportunity to learn and grow. My articles have been features in publications like <a href="https://uxdesign.cc/@fbrill" target="_blank">UX Collective</a> and <a href="https://medium.muz.li/@fbrill" target="_blank">Muzli by InVision</a>.`}
      />
      <ArticlePageContainer compact />
      <SectionHeading
        bg={data.contactSHBG}
        heading="Contact"
        intro="I would love to hear from you, so please feel free to reach out and let's start a conversation."
      />
      <ContactSection colour="#A5ED7E" />
    </Layout>
  )
}

export default IndexPage
