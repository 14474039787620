import React from 'react'
import styled from 'styled-components'
import TrackVisibility from 'react-on-screen'
import ContactDetails from '../contact/contactDetails'

const ContactSectionWrapper = styled.div`
  background-image: linear-gradient(180deg, #191126 5%, #14081e 100%);
  padding-bottom: 80px;
`

const ContactSection = ({ colour }) => (
  <ContactSectionWrapper>
    <TrackVisibility partialVisibility>
      <ContactDetails colour={colour} />
    </TrackVisibility>
  </ContactSectionWrapper>
)

export default ContactSection
