import React from 'react'
import styled from 'styled-components'
import posed from 'react-pose'

const Section = styled.div`
  text-align: center;
  img {
    max-width: 85px;
    max-height: 85px;
    margin: 50px 0;
  }
  h3 {
    text-transform: uppercase;
    font-weight: 400;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
    padding-bottom: 20px;
    strong {
      font-weight: 600;
      display: block;
    }
  }
  p {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
  }
  @media (max-width: 900px) {
    img {
      margin: 30px 0;
    }
  }
`
const ItemAnimation = posed.li({
  open: { x: 0, opacity: 1 },
  closed: { x: -150, opacity: 0 },
})

const WhatIDoItem = ({ title, description, icon, isVisible }) => (
  <ItemAnimation>
    <Section>
      <img src={icon} alt={title} />
      <h3 dangerouslySetInnerHTML={{ __html: title }} />
      <p dangerouslySetInnerHTML={{ __html: description }} />
    </Section>
  </ItemAnimation>
)
export default WhatIDoItem
